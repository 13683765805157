import { ProductListResponseData } from "@/stores/product/type.ts";
import { fetchProductListApi } from "@/services/api/product/productApi.ts";
import useLoaderStore from "../loader/loader.store.ts";
import { QueryFilter } from "../../../types/common";

export default {
  async fetchProducts(
    payload: QueryFilter,
    parentKey: string
  ): Promise<ProductListResponseData> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    try {
      const { data: productsResponse } = await fetchProductListApi(payload);
      if (parentKey === "products") {
        this.$state.products = productsResponse.data.results;
      } else {
        this.$state.products = [
          ...this.$state.products,
          ...productsResponse.data.results,
        ];
      }
      this.$state.count = productsResponse.data.count;
      return productsResponse.data;
    } catch (error) {
      console.log(error);
    } finally {
      loader.changeLoadingStatus({ isLoading: false });
    }
  },
};
