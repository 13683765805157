import { RoutePoints } from "@/components/ViaPointsPackage/types/types";
import { StoreDefinition } from "pinia";
import { CommonResponseGateWay } from "types/common";
import { ContactPersonType } from "@/stores/order/types.ts";
import { REPEAT_END_TYPE, REPEAT_TYPE } from "@/services/enums/commonEnum";
import { Product } from "@/stores/product/type.ts";

export type TripDistanceDuration = {
  oneway: number;
  return: number;
};

export type TripStartEndDate = {
  oneway: string;
  return: string;
};

export type RepeatTripConfig = {
  startDate: Date;
  dayOrWeekCount: number;
  dayOrWeek: REPEAT_TYPE.DAYWISE | REPEAT_TYPE.WEEKLY;
  selectedDays: Array<0 | 1 | 2 | 3 | 4 | 5 | 6>; // Array of selected days of the week. 0 = sunday.
  selectedEndOption:
    | REPEAT_END_TYPE.ON_END_DATE
    | REPEAT_END_TYPE.AFTER_OCCURENCE;
  endDate?: Date;
  occurence?: number;
};

export type RadiusAttr = {
  radius: number;
  center: {
    lat: number;
    lng: number;
  };
};

export type DirectOrderTrip = {
  id?: number;
  [DIRECTS_ORDER_FIELDS.TRIP_STATUS]?: string;
  [DIRECTS_ORDER_FIELDS.GEN_TID]?: string;
  [DIRECTS_ORDER_FIELDS.TEMPLATE_ID]?: string | number;
  [DIRECTS_ORDER_FIELDS.ALT_TRIP_NAME]: string;
  [DIRECTS_ORDER_FIELDS.ORDER_TYPE]: string;
  [DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]: string | Date;
  [DIRECTS_ORDER_FIELDS.TRAVEL_FROM]: string;
  [DIRECTS_ORDER_FIELDS.ROUTE_POINTS]: RoutePoints;
  [DIRECTS_ORDER_FIELDS.TRAVEL_TO]: string;
  [DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME]: string | Date;
  [DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME_RETURN]?: string | Date;
  [DIRECTS_ORDER_FIELDS.BUS_AVAILABILITY]: boolean;
  [DIRECTS_ORDER_FIELDS.DURATION]: TripDistanceDuration;
  [DIRECTS_ORDER_FIELDS.DISTANCE]: TripDistanceDuration;
  [DIRECTS_ORDER_FIELDS.POC]: ContactPersonType;
  [DIRECTS_ORDER_FIELDS.PAX]: number | string;
  [DIRECTS_ORDER_FIELDS.INVOICE_REF]: string;
  [DIRECTS_ORDER_FIELDS.COMMENT_CUSTOMER]: string;
  [DIRECTS_ORDER_FIELDS.PRICE]?: null | number;
  [DIRECTS_ORDER_FIELDS.PRICE_EX_VAT]?: null | number;
  [DIRECTS_ORDER_FIELDS.WAITING_TIME]: number;
  [DIRECTS_ORDER_FIELDS.SALES_LINE_ID]?: number;
  [DIRECTS_ORDER_FIELDS.SOL_ID]?: number;
  [DIRECTS_ORDER_FIELDS.FILE_1]?: string;
  [DIRECTS_ORDER_FIELDS.FILE_2]?: string;
  [DIRECTS_ORDER_FIELDS.FROM_RADIUS]?: RadiusAttr;
  [DIRECTS_ORDER_FIELDS.TO_RADIUS]?: RadiusAttr;
};

export type OrderFormType = Record<string, DirectOrderTrip>;
export type DirectOrderTripFieldTypes =
  | string
  | number
  | boolean
  | Date
  | string[]
  | TripDistanceDuration
  | TripStartEndDate
  | RoutePoints
  | ContactPersonType
  | object;

export type DirectOrder = {
  trip: OrderFormType;
  org_id: string;
  timezone: string;
  contact_person: ContactPersonType;
  editedTripIds: string[];
  cancel_trips: number[];
  gen_oid: string;
  order_id: string | number;
};
export interface State {
  directOrder: DirectOrder;
  errors: object;
}
interface Getters {
  getOrderFormValues: (state: State) => DirectOrderTrip[];
  getCancelTrips: (state: State) => number[];
}
interface Actions {
  addTripToDirectOrder: (product?: Product) => void;
  updateOrPushErrorTripSpecific: (
    tripId: string,
    field: keyof DirectOrderTrip,
    flag: boolean
  ) => void;
  updateLocalTripSpecific: (
    tripId: string,
    field: string,
    value: DirectOrderTripFieldTypes,
    hasError?: boolean
  ) => void;
  getLocalTripSpecific: (
    tripId: string,
    fieldName: string
  ) => DirectOrderTripFieldTypes;
  getLocalTripErrorSpecific: (tripId: string, fieldName: string) => boolean;
  cancelTripFromDirectOrder: (tripIds: (string | number)[]) => void;
  removeErrorFromDirectOrder: (tripId: string) => void;
  checkErrors: (tripId: string) => boolean;
  copyTripFromDirectOrder: (tripId: string) => void;
  repeatTripFromDirectOrder: (
    tripId: string,
    repeatConfig: RepeatTripConfig
  ) => void;
  validateTripData: (trip: DirectOrderTrip) => Record<REQUIRED_FIELDS, boolean>;
  postDirectOrder: (
    this: StoreContext,
    payloadDirectOrderPost: PayloadDirectOrderPost,
    company: string
  ) => Promise<object>;
  getOrderDetails: (
    this: StoreContext,
    payload: OrderDetailsPayload
  ) => Promise<OrderDetailsResponseData>;
  copyOrderFromDirectOrder: (startDate: Date) => void;
}

export enum REQUIRED_FIELDS {
  DEPARTURE_DATETIME = "travel_datetime",
  TRAVEL_FROM = "travel_from",
  TRAVEL_TO = "travel_to",
  ARRIVAL_DATETIME = "deliver_time",
  DURATION = "duration",
  DISTANCE = "distance",
  PAX = "pax",
}

// Create a type for the enum values
export type RequiredFieldValuesT = `${REQUIRED_FIELDS}`;

export enum DIRECTS_ORDER_FIELDS {
  ID = "id",
  GEN_TID = "gen_tid",
  TEMPLATE_ID = "template_id",
  ALT_TRIP_NAME = "trip_comment",
  TRAVEL_FROM = "travel_from",
  FROM_LAT = "from_lat",
  FROM_LONG = "from_long",
  TRAVEL_TO = "travel_to",
  TO_LAT = "to_lat",
  TO_LONG = "to_long",
  DEPARTURE_DATETIME = "travel_datetime",
  ARRIVAL_DATETIME = "deliver_time",
  ARRIVAL_DATETIME_RETURN = "return_datetime",
  ORDER_TYPE = "order_type",
  BUS_AVAILABILITY = "bus_availability",
  RETURN_FROM = "return_from",
  RETURN_FROM_LAT = "return_from_lat",
  RETURN_FROM_LONG = "return_from_long",
  RETURN_TO = "return_to",
  RETURN_TO_LAT = "return_to_lat",
  RETURN_TO_LONG = "return_to_long",
  PAX = "pax",
  COMMENT_CUSTOMER = "comment_customer",
  INVOICE_REF = "invoice_ref",
  POC = "poc",
  DURATION = "duration",
  DISTANCE = "distance",
  ROUTE_POINTS = "route_points",
  PRICE = "price_inc_vat",
  PRICE_EX_VAT = "price_ex_vat",
  WAITING_TIME = "waiting_time",
  TRIP_STATUS = "trip_status",
  SALES_LINE_ID = "sales_line_id",
  SOL_ID = "sol_id",
  ATTACHMENTS = "attachments",
  FILE_1 = "upload_file_1_s3_1",
  FILE_2 = "upload_file_2_s3_1",
  FROM_RADIUS = "from_radius",
  TO_RADIUS = "to_radius",
}

export type PayloadDirectOrderPost = {
  new_trips: Partial<DirectOrderTrip>[];
  edit_trips: Partial<DirectOrderTrip>[] | [];
  cancel_trips: number[];
  org_id: string;
  timezone: string;
  state?: string;
  id?: string;
  contact_person: ContactPersonType;
};

export interface CreateDirectOrderResponseData {
  id: string;
  order_id: string;
  order_min_date: string;
  order_max_date: string;
}

export type OrderDetailsPayload = {
  order_id: string;
  bus_company: string;
  org_id: string;
};

export type OrderDetailsResponseData = {
  order_id: string;
  gen_oid: string;
  org_id: string;
  contact_person: ContactPersonType;
  state: string;
  trips: OrderFormType;
};
export interface UploadAttachmentResponseData {
  file_urls: string[];
  message: string;
}

export interface CreateDirectOrderResponse extends CommonResponseGateWay {
  data: CreateDirectOrderResponseData;
}

export interface OrderDetailsResponse extends CommonResponseGateWay {
  data: OrderDetailsResponseData;
}
export interface UploadAttachmentResponse extends CommonResponseGateWay {
  data: UploadAttachmentResponseData;
}

/*
 * store context define as summarize of store
 */
export type StoreContext = ReturnType<
  StoreDefinition<"direct_order", State, Getters, Actions>
>;
